<template>
  <div>
    <header>
      <div class="logo">
        <p class="logoimg"></p>
        <p class="logoname">Go Crédito</p>
      </div>

      <nav>
        <a href="#home" @click="hash='home'" :class="hash=='home'?'boxLine':''">Inicio</a>
        <a
          href="#product"
          @click="hash='product'"
          :class="hash=='product'?'boxLine':''"
        >Sobre nosotros</a>
        <a
          href="#privacy"
          @click="hash='privacy'"
          :class="hash=='privacy'?'boxLine':''"
        >Política de privacidad</a>
        <!-- <a href="#features">Características</a> -->
      </nav>
      <!-- <div class="downLoad" @click="download" style="float:right;margin:20px 100px 20px 20px">
          Descargar ahora
      </div>-->
    </header>
    <div class="home" id="home">
      <div class="mytitle">
        <div class="title0">
          Lograr Un Préstamo De hasta
          <span style="color:#E25F31">$10,000</span>
        </div>
        <div class="input">
          <input type="text" placeholder="+52 Número telefónico" />
          <input type="text" placeholder="Ingresar el código enviado via SMS" />
        </div>
        <div class="downLoad" @click="download" style="margin-top:10px">Descarga la APLICACIÓN ahora</div>
      </div>
    </div>
    <div class="product" id="product">
      <p class="title">Cuáles son las ventajas de Go Crédito?</p>
      <div class="productlist">
        <div class="productitem">
          <img src="../assets/images/1@3x.png" alt />
          <div class="productTitle">Instalar la APP</div>
          <p class="itemmsg">Consequi en</p>
          <a
            href="https://www.goloannw.com/EfNO57NnogOAhZsT/"
            style="color:#E25F31;font-size:14px"
          >Google Play</a>
        </div>
        <div style="margin: 0 70px" class="productitem">
          <img src="../assets/images/2@3x.png" alt />
          <div class="productTitle">Registro</div>
          <p class="itemmsg">Introducir los datos requeridos por la aplicación</p>
        </div>
        <div class="productitem">
          <img src="../assets/images/3@3x.png" alt />
          <div class="productTitle">Realización de la verificación facial</div>
          <p class="itemmsg">Verificación facial tras la introducción de datos</p>
        </div>
        <div class="productitem">
          <img src="../assets/images/3@3x.png" alt />
          <div class="productTitle">Obtención de un préstamo</div>
          <p class="itemmsg">A la espera de la notificación del desembolso del préstamo</p>
        </div>
      </div>
    </div>

    <div class="privacy" id="privacy">
      <p class="title">Política de privacidad</p>
      <div :class="!showmore ? 'privacymsg showmore' : 'privacymsg'">
        <div>

El desarrollador de Go Crédito es hongfeng cheng. Esta Política de privacidad proporciona las políticas de Go Crédito con respecto a la recopilación y el uso de la información personal de los usuarios. Go Crédito anima a los usuarios a leer esta Política de Privacidad antes de utilizar esta aplicación.Si accede y utiliza los servicios ofrecidos en Go Crédito, acepta y está de acuerdo con las políticas de información personal descritas en esta Política de Privacidad y Términos.Para brindarle nuestros servicios, le pediremos que proporcione la información personal necesaria. Si se niega a proporcionar su información personal, es posible que no podamos brindarle nuestros servicios. Podemos recopilar los siguientes tipos de información:<br><br>

<strong> 1.Información SMS</strong><br>
Go Crédito necesita obtener la información del SMS del usuario (que incluye: título del SMS, contenido del SMS, si leerlo) cuando el usuario firma un préstamo para evaluar el valor del usuario y el análisis antifraude; Go Crédito otorga gran importancia a la seguridad de los datos del usuario y la plataforma carga los datos del usuario en https://qt.qtzhreop.com/qtz-gp, y utiliza el cifrado DES y AES para almacenar y garantizar la seguridad de los datos del usuario. Go Crédito nunca compartirá información de los usuarios con terceros.<br><br>

<strong> 2.Información de la lista de contactos</strong><br>
Cuando un usuario utiliza el servicio de préstamo de Go Crédito, Go Crédito necesita obtener la información de contacto de emergencia (que incluye: número de contacto, nombre de contacto, relación) que seleccionó en la libreta de direcciones para evitar fraudes. Los datos de contacto de emergencia cargados se almacenarán encriptados por Des y AES y se cargarán en https://qt.qtzhreop.com/qtz-gp para garantizar la seguridad de los datos y prometer no compartirlos con terceros.<br>
<br>

<strong> 3.Información personal</strong><br>
Incluyendo nombre, DNI y número de teléfono móvil para verificar la autenticidad de la información personal del usuario.<br><br>

<strong> 4.Información financiera</strong><br>
Después de que se aprueba la solicitud de préstamo, es necesario recopilar la información de la cuenta bancaria del usuario para emitir el préstamo.<br><br>

<strong> 5.Permiso de la cámara</strong><br>
Algunas funciones requieren tomar fotografías y reconocimiento facial. Este permiso ayuda a los usuarios a cargar los datos de la foto requerida durante el proceso de préstamo.<br><br>

<strong> 6.Geolocalización</strong><br>
Después de que el usuario esté de acuerdo, obtenga su información de ubicación geográfica cuando el usuario use la aplicación, para proporcionar a los usuarios algunos servicios regionales.<br><br>

<strong> 7.Información del dispositivo</strong><br>
Incluidos SSAID, dirección MAC, IMEI, modelo de hardware, versión del sistema operativo, etc., para garantizar la seguridad y la legalidad de los usuarios que utilizan este producto.
<br><br>

<strong>8. Lista de aplicaciones descargadas</strong><br>
Asistir a la plataforma en las decisiones de evaluación crediticia y control de riesgos.
<br><br>

<strong> 9. Información del calendario</strong><br>
Ayudará a los usuarios a planificar el tiempo de pago de manera razonable.<br><br>


<strong>Seguridad de información</strong><br>
La información de su cuenta se almacena en una instalación de control de acceso y está protegida con contraseña para garantizar su privacidad y seguridad. Si nuestras protecciones y acuerdos son infringidos por terceros malintencionados, no garantizamos que dicha información del usuario no se utilice indebidamente. Su uso de la aplicación es bajo su propio riesgo y discreción. Usted será el custodio de su contraseña y cumplirá con los términos aplicables mencionados en la solicitud, y no somos responsables de las acciones de otros usuarios con los que elija compartir información. Solo permitimos el acceso o el procesamiento de su información por parte de personas autorizadas que mantienen estrictamente la confidencialidad de la información. No venderemos su información personal a nadie por ningún motivo.<br><br>

<strong>Protección, almacenamiento y eliminación de información personal</strong><br>
Para garantizar la seguridad de sus datos, intentamos implementar varias medidas de seguridad en varias formas para controles físicos, técnicos y apropiados para que sus datos no se compartan, dañen o pierdan, incluidos, entre otros, SSL, almacenamiento seguro adicional, Centro de datos control. Go Crédito siempre se esforzará por mantener su información libre de dispersión, daño y pérdida. Sus datos se almacenarán de forma segura en https://qt.qtzhreop.com/qtz-gp Por motivos de seguridad y respaldo de datos, Go Crédito puede almacenar su información y datos en servidores seguros alternativos. No eliminaremos su información personal sin autorización. Cuando decida dejar de usar la aplicación Go Crédito, puede comunicarse con nosotros por correo electrónico (hahahfc2221@gmail.com) y eliminaremos su información personal relevante de acuerdo con sus necesidades.<br>
<br>

<strong>Intercambio de información</strong><br>
Usted comprende que podemos compartir la información personal de los usuarios en las siguientes circunstancias:
1. Proporcionar información personal a agencias gubernamentales, tribunales u otras agencias gubernamentales cuando lo permita o exija la ley;
2. Para prevenir operaciones fraudulentas o no autorizadas, reales o potenciales;
3.Investigar el fraude que ha ocurrido o sospecha de fraude.
Cookies y otras tecnologías
Dependiendo de la funcionalidad ofrecida, Go Crédito puede utilizar cookies y otras tecnologías. Las cookies y otras tecnologías se pueden usar para recopilar información, como el tipo de navegador y el sistema operativo, rastrear la cantidad de visitantes del sitio web y comprender cómo los visitantes usan las aplicaciones. Las cookies también pueden ayudar a personalizar las aplicaciones para los visitantes, pero la información personal no se puede recopilar a través de cookies y otras tecnologías.<br><br>

<strong>Cambios a esta Política de Privacidad</strong><br>
Esta Política de privacidad está sujeta a cambios en cualquier momento y le recomendamos que la revise periódicamente. Si continúa utilizando el Servicio después de que realicemos cambios en la Política de privacidad, reconoce y acepta los cambios y está sujeto a la Política de privacidad revisada. Le recomendamos que revise y vuelva a leer esta Política de privacidad cuando visite Go Crédito para asegurarse de que comprende cómo se utilizará la información personal que nos proporcione. Nos comprometemos a proteger la privacidad de su información personal dentro de un alcance razonable de acuerdo con las leyes y regulaciones aplicables, y no divulgaremos su información personal sin su consentimiento por escrito.<br><br>

<strong>Contáctenos</strong><br>
Si tiene alguna pregunta sobre esta Política de privacidad, puede contactarnos en:<br>
Correo electrónico: hahahfc2221@gmail.com<br>
Dirección: Sierra Nevada 115, Lomas - Virreyes, Lomas de Chapultepec, Miguel Hidalgo, 11000 Ciudad de México<br>
Horario de trabajo: Lunes a Viernes 9:00~18:00 Sábado, Domingo 9:00~17:00
</div>

        <p v-show="showmore" @click="showmore = !showmore" class="show"></p>
      </div>
    </div>
    <footer>
      <!-- <p class="title">Contact Us</p> -->
      <div class="contact">
        <!-- <div>
          <img width="25px" src="../assets/images/phone.png" alt />
          5156867204
        </div> -->
        <div>
          <img width="25px" src="../assets/images/email.png" alt />
          hahahfc2221@gmail.com
        </div>
        <div>
          <img width="25px"  src="../assets/images/address.png" alt />
          <span >
            Sierra Nevada 115, Lomas - Virreyes,
            Lomas de Chapultepec, Miguel Hidalgo,
            11000 Ciudad de México
          </span>
        </div>
      </div>
      <p class="end">@Copyright by Go Crédito</p>
    </footer>
  </div>
</template>
<style lang="less">
input {
  outline-style: none;
  border: 1px solid #c0c4cc;
  border-radius: 5px;
  width: 100%;
  height: 48px;
  padding: 10px 20px;
  margin-bottom: 30px;
  box-sizing: border-box;
  font-family: "Microsoft soft";
  &:focus {
    border-color: #f07b00;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), #f07b00;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), #f07b00;
  }
}

.boxLine {
  color: #e25f31;
  // border-bottom: 5px solid #E25F31;
}
.downLoad {
  width: 300px;
  height: 48px;
  background: #e25f31;
  text-align: center;
  line-height: 48px;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  border-radius: 24px;
  margin: 0 auto;
}

a {
  text-decoration: none;
}
strong {
  font-weight: 600;
}
.title {
  padding: 50px 0;
  font-family: Helvetica-Bold;
  font-size: 24px;
  color: #333333;
  text-align: center;
  font-weight: 600;
}

header {
  width: 100%;
  height: 80px;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 1000000000000;
  line-height: 80px;

  .logo {
    margin-left: 167px;
    float: left;

    .logoimg {
      margin-top: 15px;
      width: 48px;
      height: 48px;
      background: url(../assets/images/logo@3x.png) no-repeat;
      background-size: 100%;
      float: left;
    }

    .logoname {
      font-family: Helvetica-Bold;
      font-size: 14px;
      color: #333333;
      float: left;
      margin-left: 20px;
    }
  }

  nav {
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 167px;

    a {
      margin: 0 40px;
      font-family: Helvetica-Bold;
      font-size: 18px;
      color: #333333;
      text-align: left;
      text-align: center;
      /* border-bottom: 2px solid #fff; */
    }

    a:hover {
      color: #e25f31;
      /* border-bottom: 2px solid rgba(61,76,233); */
      transition: 0.3s;
    }
  }
}

.home {
  background: url(../assets/images/banner.png) no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 800px;
  overflow: hidden;
  margin-top: 70px;
  position: relative;
  img {
    width: 700px;
    height: 100%;
    margin-top: 250px;
    margin-left: 150px;
  }

  .lbr {
    width: 80px;
    height: 16px;
    background-color: #fff;
    margin: 60px 0;
    margin-left: 150px;
  }

  .googlelogo {
    display: block;
    background: url(../assets/images/Googleplay.png) no-repeat;
    background-size: 100%;
    width: 376px;
    height: 112px;
    margin-top: 80px;
    margin-left: 150px;
  }
  .mytitle {
    position: absolute;
    margin-top: 150px;
    margin-left: 150px;
    width: 450px;
    height: 400px;
    box-shadow: 0px 0px 24px 0px rgba(226, 95, 49, 0.1);
    border-radius: 20px;
    padding: 30px 40px;
    .title0 {
      font-size: 28px;
      color: #000;
      line-height: 40px;
      margin-bottom: 50px;
    }

    .title1 {
      font-size: 16px;
      font-weight: 400;
      color: #000;
      line-height: 30px;
      margin-bottom: 20px;
    }
  }
  .rightBox_title {
    position: absolute;
    right: 450px;
    font-size: 48px;
    color: #fff;
    top: 100px;
  }
  .rightBox {
    position: absolute;
    right: 200px;
    top: 200px;
    font-size: 28px;
    .yan {
      color: #e25f31;
      font-size: 30px;
    }
    .min {
      margin-top: 10px;
      font-size: 14px;
    }
    div {
      margin-bottom: 50px;
    }

    .title {
      font-size: 48px;
      color: #fff;
    }
  }
}

.product {
  .productlist {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 410px;

    .productitem {
      float: left;
      text-align: center;
      width: 460px;
      height: 300px;
      padding: 20px;
      background-color: #fff;
      font-size: 16px;
      border-radius: 30px;
      .productTitle {
        color: #e25f31;
      }
      img {
        width: 100px;
        height: 100px;
        margin-top: 30px;
      }

      .itemmsg {
        font-family: Helvetica;
        font-size: 14px;
        color: #333333;
        text-align: center;
        line-height: 40px;
        margin-top: 10px;
      }
    }
  }
}

.company {
  padding: 0 280px;
  margin-top: 180px;

  .companylist {
    .companyitem {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 400px;

      .left {
        img {
          width: 586px;
          height: 414px;
        }
      }

      .right {
        .msg {
          max-width: 540px;
          font-family: Helvetica;
          font-size: 20px;
          color: #333333;
          text-align: left;
          line-height: 60px;
        }
      }
    }
  }
}

.features {
  padding: 0 200px;
  margin-top: 180px;

  .companyitem {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 400px;
    margin-bottom: 300px;

    .left {
      img {
        width: 586px;
        height: 414px;
      }
    }

    .right {
      .msg {
        max-width: 800px;
        font-family: Helvetica;
        font-size: 20px;
        color: #666666;
        text-align: left;
        line-height: 60px;
      }
    }
  }
}

.about {
  height: 500px;
  padding: 80px 180px;
  background: #e25f31;
  text-align: center;

  .title {
    font-family: Helvetica-Bold;
    font-size: 44px;
    color: #ffffff;
    padding: 0;
    padding-bottom: 60px;
  }

  .aboutmsg {
    font-family: Helvetica;
    font-size: 24px;
    color: #ffffff;
    line-height: 60px;
    text-align: left;
  }
}

.privacy {
  padding: 0px 210px;
  padding-bottom: 100px;
  position: relative;

  .title {
    font-weight: 600;
    padding: 80px 0;
    font-size: 22px;
  }

  .privacymsg {
    font-family: Helvetica;
    font-size: 20px;
    height: 550px;
    overflow: hidden;
    text-align: left;
    line-height: 50px;

    p {
      font-weight: 600;
      font-size: 20px;
      margin-top: 40px;
      text-align: center;
    }

    .show {
      background: url(../assets/images/getMore.png) no-repeat;
      background-size: 100%;
      width: 60px;
      height: 60px;
      position: absolute;
      top: 750px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  .showmore {
    height: auto;
    overflow: auto;
  }
}

footer {
  height: 250px;
  background: #2f2f2f;
  color: #fff;

  .title {
    color: #fff;
    padding: 80px 0;
  }

  .contact {
    padding-top: 30px;
    width: 100%;
    font-size: 18px;
    color: #ffffff;
   
    div {
      font-family: Helvetica;
      font-size: 18px;
      color: #ffffff;
      text-align: center;
      line-height: 31px;
      img {
        margin-right: 5px;
      }
    }
  }

  .end {
    width: 100%;
    text-align: center;
    font-family: Helvetica;
    font-size: 16px;
    color: #ffffff;
    background: #1a1a1a;
    height: 80px;
    line-height: 80px;
    margin-top: 60px;
  }
}
</style>
<script>
export default {
  name: "Home",
  data() {
    return {
      showmore: true,
      hash: window.location.hash,
    }
  },
  methods: {
    download() {
      window.open("https://www.goloannw.com/EfNO57NnogOAhZsT/")
    },
  },
}
</script>
